//-
// Build json of content model expected by theme:
// i.e. it mimics the response of the graphql request from CMS
// Files containing additional data models:
//   header.js for Navbar
//   footer.js 
//-
export const LandingData = {
    "data": {
        "homepage": {
            "id": "aa0001",
            "title": "ESG Controlling",
            "description":"",
            "blocks": [
                {
                  "id": "a10000",
                  "blocktype":"HomepageHero",
                  "kicker": "Ihr Partner im ESG-Controlling",
                  "h1":"Messbare  Indikatoren, verbesserte Prozesse und Transparenz sind der Schlüssel für zielgerichtetes nachhaltiges Wirtschaften",
                  "subhead": "Starten Sie jetzt Ihre nachhaltige Zukunft mit unserem aussagekräftigem ESG-Controlling. Vereinbaren Sie noch heute ein Beratungsgespräch.",
                  "links": [
                      {
                        "id": "a34723",
                        "href":"https://calendly.com/esg-controlling",
                        "text": "Jetzt starten!"
                      }
                    ]                
                },

                {
                  "id": "b10000",
                  "blocktype":"HomepageConversion",
//                  "kicker": "Was wir für Sie tun können",
//                  "kicker": "Warum ESG-Controlling für jedes Unternehmen wichtig ist",
                  "heading": "Warum ESG-Controlling für jedes Unternehmen wichtig ist",
                  //"text":"In den folgenden Abschnitten erfahren Sie mehr über unsere Angebote im ESG-Controlling ",
                  "value01":"Machen Sie jetzt den nächsten Schritt für eine nachhaltige Zukunft Ihres Unternehmens. " +
                            "Überzeugen Sie sich selbst von den Vorteilen des ESG-Controllings und erfahren Sie, wie Sie Ihre " +
                            "Nachhaltigkeitsleistungen messen, bewerten und verbessern können.",
                  "value02":"Unser ESG-Controlling ist der " +
                            "Weg zu einer transparenten und nachhaltigen Unternehmensführung. Nutzen Sie die Chance und starten " +
                            "Sie jetzt durch - navigieren Sie weiter durch unsere Seite, um alle Details zu erfahren:",
                  "content": [
                      {
                        "id": "b10001",
                        "heading": "01",
                        "subheading":"Wie können Sie im Unternehmensalltag Ihre Nachhaltigkeitsleistungen messen und bewerten?",
                        "action": "",
                        "children": [
                            {
                              "id": "b10011",
                              "textContent":"Indikatoren und KPIs im ESG-Controlling sind Innovationsmotor und ermöglichen Ihnen den direkten Einstieg in eine nachhaltige Produktentwicklung inklusive der Umsetzung eines grundlegenden Kreislaufwirtschaftskonzepts."
                            },
                            {
                              "id": "b10012",
                              "textContent":"ESG-Controlling ermöglicht die bereits heute notwendige Verknüpfung zwischen ökonomischen, " +
                              "sozialen und ökologischen Anforderungen und den Auswirkungen Ihres unternehmerischen Handelns einfach und schnell herzustellen."
                            }
                        ]
                      },
                      {
                        "id": "b10002",
                        "heading": "02",
                        "subheading":"Ihre Kunden sind an Informationen über Ihre Nachhaltigkeitsleistungen interessiert?",
                        "action": "",
                        "children": [
                            {
                              "id": "b10021",
                              "textContent":"Mit dem ESG-Controlling stellen Sie sich gezielt auf die steigenden Anforderungen der " +
                              "Nachhaltigkeitsberichterstattung und des Lieferkettensorgfaltspflichtengesetz vor. "
                            },
                            {
                              "id": "b10022",
                              "textContent":"Es stellt Ihre Leistungen " +
                              "und Aktivitäten in Bezug auf Umwelt, Gesellschaft und Governance (ESG) sowohl unternehmensintern als auch " +
                              "gegenüber Kunden und Stakeholdern nachvollziehbar dar." 
                            }
                        ]
                      },
                      {
                        "id": "b10003",
                        "heading": "03",
                        "subheading":"Kennen Sie Ihre Emissionen?",
                        "action": "",
                        "children": [
                            {
                              "id": "b10031",
                              "textContent":"Wir zeigen Ihnen, wie hoch sie sind. Daraus erarbeiten wir gemeinsam Handlungsfelder für ihre Reduktion."
                            },
                            {
                              "id": "b10032",
                              "textContent":"Ein kontinuierliches Carbon Accounting ist ein wichtiger strategischer Erfolgsfaktor. Dieser Bereich des ESG-Controlling ist damit entscheidend für Ihre Kosteneffizienz und bestimmt Ihre langfristige Wettbewerbsfähigkeit."
                            },
                        ]
                      },
                  ]                  
                }, // HomepageConversion
                

                {
                  "id": "c10000",
                  "blocktype":"HomepageVorteile",
                  "kicker": "",
                  "heading": "Vorteile des ESG-Controllings",
                  "text":"Zur vereinfachten Datenerhebung und -Auswertung bieten wir auch digitale Lösungen an, die effektiv und zuverlässig sind. " +
                          "Sie bieten eine praktische Sicht auf ihre Nachhaltigkeitsentwicklung, vereinfachen ESG-Reportingpflichten und unterstützen " +
                          "nahtlos datengetriebene Entscheidungsprozesse.",
                  "content": [
                      {
                        "id": "c10001",
                        "heading": "",
                        "text":"",
                        "children": [
                            {
                              "id": "c10011",
                              "textContent":"Unsere Beratungsschwerpunkte in den Bereichen Nachhaltigkeit, Klimaneutralität, Innovation und Zirkularität " +
                              "können mithilfe verschiedener Indikatoren dargestellt und beschrieben werden, um sie messbar und bewertbar zu machen."
                            },
                            {
                              "id": "c10012",
                              "textContent":"Wir bieten Ihnen ein aussagekräftiges ESG-Controlling, das individuell auf Ihr Unternehmen zugeschnitten ist " +
                              "und Sie bei der Umsetzung von nachhaltigen Aktivitäten unterstützt."
                            }
                        ]
                      },
                      {
                        "id": "c10002",
                        "heading": "",
                        "text":"",
                        "children": [
                            {
                              "id": "c10021",
                              "textContent":"ESG-Controlling ermöglicht die zielgerichtete Anpassung von Geschäftsmodellen und vermeidet zugleich Kostensteigerungen durch die transparente Umsetzung Ihrer Nachhaltigkeitsstrategie."
                            },
                            {
                              "id": "c10022",
                              "textContent":"Eine Verbesserung des Images als nachhaltiges, klimaneutrales Unternehmen ist ein angenehmer Nebeneffekt. "
                            }                            
                        ]
                      },
                  ]                  
                }, // HomepageWasIst
                

                {
                  "id": "c20000",
                  "blocktype":"HomepageWasIst",
                  "kicker": "",
                  "heading": "Was ist ESG-Controlling?",
                  "text":"Das ESG-Controlling ist ein wichtiger Bestandteil einer zukunftsorientierten Unternehmensführung und hilft Unternehmen, Ihre Nachhaltigkeitsziele zu erreichen und sich an gesetzlichen Vorgaben zu orientieren. Es ist die notwendige Grundlage für eine transparente und verantwortungsvolle Unternehmensführung im 21. Jahrhundert.",
                  "content": [
                      {
                        "id": "c20001",
                        "heading": "",
                        "text":"",
                        "children": [
                            {
                              "id": "c20011",
                              "textContent":"Das ESG-Controlling ist eine Erweiterung des klassischen Berichtswesens und umfasst die Analyse und Überwachung von Nachhaltigkeitsaspekten im Unternehmen. Es berücksichtigt nicht nur wirtschaftliche Prozesse, sondern auch soziale und ökologische Faktoren und deren Auswirkungen auf das Unternehmen und die Umwelt."
                            },
                            {
                              "id": "c20099",
                              "textContent":""
                            }
                        ]
                      },
                      {
                        "id": "c20002",
                        "heading": "",
                        "text":"",
                        "children": [
                            {
                              "id": "c20012",
                              "textContent":"Das ESG-Controlling basiert auf einer Vielzahl von Nachhaltigkeitsindikatoren, die die Leistung des Unternehmens in Bezug auf Umwelt, Gesellschaft und Governance messen und bewerten. Durch die Integration von Carbon Accounting und der zirkulären R-Strategie bietet das ESG-Controlling eine umfassende Perspektive auf die Nachhaltigkeitsleistung des Unternehmens."
                            }
                        ]
                      },                      
                  ]                  
                }, // HomepageWasIst


                {
                  "id": "c30000",
                  "blocktype":"HomepageScorecard",
                  "kicker": "",
                  "heading": "Die Transparente Scorecard für ESG-Controlling",
                  "text":"Unsere Kunden werden durch ESG-Controlling auf die gestiegenen Nachhaltigkeitsanforderungen vorbereitet und können die erhobenen Daten als Nachweis für eine verantwortungsvolle Unternehmensführung nutzen und Greenwashing vermeiden.",
                  "content": [
                      {
                        "id": "c30001",
                        "heading": "",
                        "text":"",
                        "children": [
                            {
                              "id": "c30011",
                              "textContent":"Für unsere Kunden ist ESG-Controlling eine stufenweise Erweiterung des bestehenden Controllingsystems. Wichtige Bereiche der Nachhaltigkeit können schrittweise ergänzt werden und lassen sich von uns gemeinsam mit dem Controller vor Ort auf den richtigen Weg bringen. Der Nutzen aus den erhöhten Nachhaltigkeitsanforderungen des Gesetzgebers, der Kunden und anderer Interessengruppen wird durch begleitendes ESG-Controlling transparenter."
                            },
//                            {
//                              "id": "c30012",
//                              "textContent":""
//                            }
                        ]
                      },
                      {
                        "id": "c30002",
                        "heading": "",
                        "text":"",
                        "children": [
                            {
                              "id": "c30021",
                              "textContent":"Die ESG-Controlling Scorecard ermöglicht Ihnen, Fortschritte und Herausforderungen bei der Umsetzung Ihrer " +
                                            "Nachhaltigkeitsziele unkompliziert zu erkennen. Im Beispiel links wird der Erfüllungsgrad ausgewählter KPIs " +
                                            "abgebildet, während wir unterschiedliche Rating- und Analysemodelle anbieten, die auf Ihre Aufgabenstellungen " +
                                            "und Zielsetzungen zugeschnitten sind und Ihnen konkrete Handlungsfelder aufzeigen."
                            },
//                            {
//                              "id": "c30022",
//                              "textContent":""
//                            }
                        ]
                      }
                  ]                  
                }, // HomepageNutzen

                {
                  "id": "c40000",
                  "blocktype":"HomepageEinsatz",
                  "kicker": "",
                  "heading": "Wo setzen wir ESG-Controlling ein?",
                  "text":"ESG-Controlling betrachtet die gesamte Wertschöpfungskette",
                  "content": [
                      {
                        "id": "c40001",
                        "heading": "",
                        "text":"",
                        "children": [
                            {
                              "id": "c40011",
                              "textContent":"Die immer strengeren gesetzlichen Anforderungen an das transparente Berichtswesen in Unternehmen erfordern eine Betrachtung " +
                              "der gesamten Wertschöpfungskette. Dieser Ansatz beinhaltet alle Schritte von der Rohstoffbeschaffung bis zum Ende des Produktlebenszyklus " +
                              "unter Berücksichtigung des Prinzips der Kreislaufwirtschaft, einschließlich der Wiederverwendung von Materialien und anderer Ressourcen."
                            },
                            {
                              "id": "c40012",
                              "textContent":"Das Ziel besteht darin, die Auswirkungen des Unternehmens auf die Umwelt und die Gesellschaft im gesamten Lebenszyklus eines " +
                              "Produkts zu berücksichtigen und so Risiken und Chancen zu identifizieren und zu verstehen, um die Nachhaltigkeit des Unternehmens zu verbessern."
                            }                            
                        ]
                      },
                      {
                        "id": "c40002",
                        "heading": "",
                        "text":"",
                        "children": [
                            {
                              "id": "c40013",
                              "textContent":"ESG-Controlling berücksichtigt diese Anforderungen, wie das Schaubild auf der nächsten Seite zeigt. Im Mittelpunkt steht " +
                              "das Unternehmen und seine Fähigkeit, langfristig Gewinne zu erzielen, Steuern zu zahlen und sichere Arbeitsplätze zu garantieren."
                            },
                            {
                              "id": "c40014",
                              "textContent":"Die Daten in der vor- und nachgelagerten Wertschöpfungskette sind teilweise schwer zu erheben. Sie sind aber nicht nur " +
                              "wichtig im Carbon Accounting, sondern auch für die Berichtspflicht im Rahmen des Lieferkettensorgfaltspflichtengesetzes."
                            },
                            {
                              "id": "c40099",
                              "textContent":"ESG-Controlling trägt direkt zum wirtschaftlichen Impact der Zirkularität bei, in dem es präzise KPIs für die Umsetzung " +
                              "des Cradle-to-Cradle Konzepts bereitstellt."
                            }

                        ]
                      },
                  ]                  
                }, // HomepageEinsatz
                {
                  "id": "c50000",
                  "blocktype":"HomepageAufwand",
                  "kicker": "",
                  "heading": "Aufwand bei der Umsetzung des ESG-Controllings",
                  "text":"",
                  "content": [
                      {
                        "id": "c50001",
                        "heading": "",
                        "text":"",
                        "children": [
                            {
                              "id": "c50011",
                              "textContent": "ESG-Controlling stellt für unsere Kunden keine große Investition in geeignete Lösungen dar. " +
                              "Vielmehr bieten wir einen erweiterten Planungshorizont für ein stetig wachsendes Controlling-Rahmenwerk als " +
                              "Ideengeber und Enabler. Mit unserem Schritt-für-Schritt-Ansatz können Kosten genau kontrolliert und die " +
                              "Geschwindigkeit der Umsetzung angepasst werden."
                            }
                        ]
                      },
                      {
                        "id": "c50002",
                        "heading": "",
                        "text":"",
                        "children": [
                            {
                              "id": "c50012",
                              "textContent": "Es muss nicht gleich perfekt sein - besser werden ist auch gut. " +
                              "Wir empfehlen jedoch, die neu definierten Prozesse zur Datenerfassung und -messung von Anfang an zu digitalisieren, " +
                              "damit zukünftige Umstellungen und die Erstellung Ihres Nachhaltigkeitsberichts nicht zu kostspielig werden." 
                            },
                            {
                              "id": "c50013",
                              "textContent": "Ein digitalisiertes ESG-Controlling transformiert den Controller von einem Berichterstatter zum Berater."
                            }
                        ]
                      },
                  ]                  
                }, // HomepageAufwand
                {
                  "id": "c60000",
                  "blocktype":"HomepageSDG",
                  "kicker": "",
                  "heading": "Beitrag des ESG-Controllings zu den SDGs",
                  "text":"",
                  "content": [
                      {
                        "id": "c60001",
                        "heading": "",
                        "text":"",
                        "children": [
                            {
                              "id": "c60011",
                              "textContent":"Für die Darstellung Ihrer Nachhaltigkeitsaktivitäten werden immer häufiger die Sustainable Development Goals (SDGs) der UN " + 
                              "herangezogen. Die übersichtliche Kachelstruktur ist mittlerweile sehr bekannt und wird von KMUs wie großen Unternehmen, NGOs oder im " + 
                              "öffentlichen Sektor verwendet."
                            }
                        ]
                      },
                      {
                        "id": "c60002",
                        "heading": "",
                        "text":"",
                        "children": [
                            {
                              "id": "c60012",
                              "textContent":"Unser Partner SUSTAINABILITY INNOVATION HUB bietet ein Assessment an, mit dem Ihre Beiträge zur Erreichung verschiedener Nachhaltigkeitsziele quantifiziert werden. " + 
                              "Durch ein intensives ESG-Controlling werden Ihre Ziele unterstützt und hervorgehoben. Die Dokumentation Ihrer Nachhaltigkeitsleistungen " + 
                              "wird durch unsere professionelle Unterstützung noch ansprechender und glaubwürdiger."
                            }
                        ],
                        "links": [
                          {
                            "id": "c60101",
                            "href": "https://www.sihub.de ",
                            "text": "SUSTAINABILITY INNOVATION HUB"
                          },
                          {
                            "id": "c60102",
                            "href": "https://sdgs.un.org",
                            "text": "UN Sustainable Development Goals"
                          }
                        ] // links
                      },
                  ]                  
                }, // HomepageSDG


                {
                  "id": "c54782",
                  "blocktype":"HomepageCta",
                  "kicker": "Ihre Ansprechpartner",
                  "heading": "Verschaffen Sie sich einen entscheidenden Vorteil",
                  "text": "Zeit ist kostbar in einer sich schnell verändernden Welt. " +
                          "Vereinbaren Sie noch heute ein Gespräch und wir zeigen Ihnen, wie Sie mit " +
                          "ESG-Controlling Ihr Geschäft voranbringen können.",
                  "links": [
                      {
                        "id": "c51ahh",
                        "href":"https://calendly.com/esg-controlling",
                        "text": "Gesprächstermin vereinbaren"
                      }
                    ]
                }           
            ] // blocks
        } // homepage
    } // data
};