import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  Flex,
  FlexList,
  Container,
  Section,
  Heading, 
  SubheadSmaller,
  Button,
  ButtonList,
  Kicker,
  Box,
} from "./ui"
import { 
  containerCtaPosition,
  paddingBoxRightLeft,
  textLeft,
  ctaImageShowHide,
} from "../tailor.module.css"


export default function HomepageCta(props) {

  const [isOpen, setIsOpen] = React.useState(false);

  //console.log("....................98 isOpen:",isOpen)

    return (
    <Container id="contact" width="fullbleed" >
      
      <Section padding={5} radius="large" background="background">
        <Box 
          className={ `${paddingBoxRightLeft}` }
          >


          <Flex id="flex1" variant="row" responsive
            style={{ alignItems: "flex-start" , padding: "0" }}
            >

              <Box id="box2">

                <Heading >
                  {props.kicker && 
                  <Kicker >
                    {props.kicker}
                  </Kicker>}
                  {props.heading}
                </Heading>
                <SubheadSmaller width="eighty" 
                  style={{ paddingBottom: "10px" }} 
                  className={textLeft}
                  >
                    {props.text}
                </SubheadSmaller>
                  <ButtonList links={props.links} style={{ marginTop: "20px" }} />

              </Box>

              <Box id="box3" 
                style={{ paddingLeft: "50px" }}
                className={ `${ctaImageShowHide}` } >
                <StaticImage  src="../images/cta_03_w1200.png" alt="contact us" width={400} placeholder="blurred" />
              </Box>


          </Flex>

        </Box>
      </Section>
    </Container>
  )
}